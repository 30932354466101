import { defineStore } from '@/pinia/defineStore.js' //引入
import { getlocalStorage, location, setlocalStorage, setSessionStorage, getSessionStorage, toast,couponPopup , jsonP, jsonS, inMiniprogram, getOrderSource } from '../../utils/index'
import { loginApi, getUserAddress, getScoreInfoApi, getFreightApi } from '../../api/user'
import { Notify } from 'vant'
import gcoord from 'gcoord';
import router from '@/router'
// import { urlEntry, paths } from '@/utils/urlEntry'


const useStore = defineStore({
    id: 'userStore',
    state: () => {
        return {
            userInfo: getlocalStorage('userInfo') ? jsonP(getlocalStorage('userInfo')) : null,
            token: getlocalStorage('token') ? getlocalStorage('token') : null,
            locationInfo: getSessionStorage('locationInfo') ? jsonP(getSessionStorage('locationInfo')) : null,     //位置信息
            address: [],
            buyAddress: null,
            appId: getlocalStorage('appId') ? getlocalStorage('appId') : null,
            extUserId: getlocalStorage('extUserId') ? getlocalStorage('extUserId') : null,
            myFreight: 0,
            freight: 0,
            minFreightFree: 0,
            selectedVoucherInfo: null
        }
    },
    getters: {
        urlEntry() {   //域名   官方渠道为0   公司自营为1
            let val = window.myurlEntry()
            return val
        },
        paths() {
            let val = window.mypaths()
            return val
        },
        createLink() {
            const { protocol, host } = window.location;
            const link = `${protocol}//${host}?appId=${this.appId}&shareUserId=${this.userInfo.id}`;
            return link;
        },
        selectedVoucherMoney(){
            return this.selectedVoucherInfo ? this.selectedVoucherInfo.amount : 0
        }
    },
    actions: {
        //登录
        login(phone, verificationCode) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                if (!this.appId) return toast('没有appId')
                const req = {
                    orderSource: getOrderSource(),
                    appId: this.appId,
                    extUserId: this.extUserId,
                    verificationCode,
                    phone
                }
                let res = await loginApi(req)
                console.log(res);
                if (res.data?.loginToKen) {
                    this.setUserInfo(res.data)
                    this.setToken(res.data.loginToKen)
                    this.getUserAddress()
                    //
                    if(res.data.addUserFlag && res.data.mallCouponUser){
                        couponPopup(res.data.mallCouponUser)
                    }
                    // 
                    resolve(res.data)
                } else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    this.upDateUserInfo()
                    reject()
                }
            })
        },
        // 
        async quit() {
            const model = (await import('@/components/model/index')).default
            model({ content: '确定退出登录吗？' })
                .then(() => {
                    if (router.history.current.name != 'goodsDetails') {
                        router.push({ name: 'goodsDetails' })
                    }
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('extUserId')
                    this.address = []
                    this.buyAddress = null
                    this.userInfo = null
                    this.token = null
                    this.extUserId = null
                    return
                })
        },
        // 
        setUserInfo(userinfo) {
            this.userInfo = userinfo
            setlocalStorage('userInfo', jsonS(this.userInfo))
        },
        setToken(token) {
            this.token = token
            setlocalStorage('token', this.token)
        },
        //更新用户信息和token
        upDateUserInfo() {
            this.userInfo = getlocalStorage('userInfo') ? jsonP(getlocalStorage('userInfo')) : null
            this.token = getlocalStorage('token') ? getlocalStorage('token') : null
        },
        //获取并存储openId
        setAppId(data) {
            const { appId, extUserId } = data
            if (extUserId) {
                this.extUserId = extUserId
                setlocalStorage('extUserId', extUserId)
            }
            if (appId) {
                this.appId = appId
                setlocalStorage('appId', appId)
            }
        },
        //百度定位
        getLocationBmap() {
            let that = this
            let geolocation = new window.BMap.Geolocation();
            if (geolocation) {
                // 开启SDK辅助定位，仅当使用环境为移动web混合开发，且开启了定位sdk辅助定位功能后生效
                geolocation.enableSDKLocation();
                // 位置选项
                const positionOptions = {
                    enableHighAccuracy: true, // 要求浏览器获取最佳结果
                    timeout: 30, //    超时时间
                    maximumAge: 0 // 允许返回指定时间内的缓存结果。如果此值为0，则浏览器将立即获取新定位结果
                };
                // 获取用户位置信息
                geolocation.getCurrentPosition(position => {
                    Notify(123);
                    // 获取定位结果状态码
                    const statusCode = geolocation.getStatus();
                    console.log(statusCode);
                    let msg = "由于未知错误而无法检索设备的位置"; // 提示消息
                    // 判断结果状态码，为0代表获取成功，读取省市、经纬度
                    switch (statusCode) {
                        case 0:
                            msg = "获取地理位置定位请求成功";
                            if (position.point) {
                                let res = gcoord.transform([position.point.lng, position.point.lat], gcoord.BD09, gcoord.GCJ02)
                                that.locationInfo = { lat: res[1], lng: res[0] }
                                setSessionStorage('locationInfo', jsonS(that.locationInfo))
                            } else {
                                msg = "由于未知错误而无法检索设备的位置";
                            }
                            break;
                        case 2:
                            msg = "由于未知错误而无法检索设备的位置";
                            break;
                        case 4:
                        case 5:
                            msg = "位置服务请求非法";
                            break;
                        case 6:
                            msg = "应用程序没有使用位置服务的权限";
                            break;
                        case 7:
                            msg = "网络不可用或者无法连接到获取位置信息的卫星";
                            break;
                        case 8:
                            msg = "无法在指定的最大超时间隔内检索位置信息";
                            break;
                        default:
                            msg = "由于未知错误而无法检索设备的位置";
                            break;
                    }
                    if (statusCode != 0) { Notify(msg); }
                }, positionOptions);
            } else {
                console.log("您的浏览器不支持地理位置服务");
                Notify("您的浏览器不支持地理位置服务");
            }
        },
        //定位原生失败继续调腾讯
        async getLocation() {
            if (getSessionStorage('locationInfo')) return
            const that = this
            location().then((res) => {
                that.locationInfo = res
                setSessionStorage('locationInfo', jsonS(that.locationInfo))
            })
        },
        //获取用户收货地址
        async getUserAddress() {
            let res = await getUserAddress()
            console.log(res);
            if (res.code == 0) {
                this.address = res.data
                this.buyAddress = res.data.length ? this.address[0] : null
                console.log(this.buyAddress);
            }
        },
        //获取用户积分
        async getScoreInfo() {
            let res = await getScoreInfoApi()
            if (res.code == 0) {
                if (res.data != this.userInfo.mallScore) {
                    this.userInfo.mallScore = res.data
                    this.setUserInfo(this.userInfo)
                }
            }
        },
        //获取运费 包邮门槛 预付运费金额
        async getFreight() {
            let res = await getFreightApi()
            if (res.code == 0) {
                console.log(res, '运费信息');
                this.minFreightFree = res.data.minFreightFree
                this.freight = res.data.freight
                this.myFreight = res.data.downPayment
                if(inMiniprogram()){
                    this.myFreight = 0
                }
            }
        }
    }

})

export default useStore