<template>
  <div class="mack" v-if="mackShow">
    <van-dialog v-model="isShow" :showConfirmButton="false">
      <div class="tipbox" v-if="!text.custom">
        <div class="Cancel_icon" @click="cancel" v-if="text.showCancelIcon">
          <van-icon name="cross" />
        </div>
        <div
          class="title"
          :style="{background:text.titleBg,color:text.titleColor}"
        >{{text.title ? text.title : '温馨提示'}}</div>
        <div class="mesg mesg1" v-if="text.html" v-html="text.html" />
        <div class="mesg" v-else>
          <span>{{text.content ? text.content : '确定该操作吗？'}}</span>
        </div>
        <div class="footer" v-if="!text.notShowFooter">
          <button
            @click="cancel"
            v-if="!text.noCancelShow"
            class="cancel"
            :style="text.cancelBtnStyle"
          >{{text.cancelText ? text.cancelText : '取消'}}</button>
          <button
            :style="{background:text.okBtnBg,color:text.okBtnColor}"
            @click="confirm"
          >{{text.okText ? text.okText : '确定'}}</button>
        </div>
      </div>
      <div v-else v-html="text.custom"></div>
    </van-dialog>
  </div>
</template>
    
<script>
import { Dialog } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      mackShow: true,
      isShow: false,
      text: {
        custom:'',
        html: "",
        title: "",
        content: "",
        okText: "",
        cancelText: "",
        noCancelShow: "",
        okBtnBg: "#fff",
        okBtnColor: "blue",
        titleBg: "#fff",
        titleColor: "#000",
        notShowFooter: false,
        showCancelIcon: true,
        cancelBtnStyle:{}
      }
    };
  },
  methods: {
    cancel() {
      this.isShow = false;
      console.log("取消");
    },
    confirm() {
      this.isShow = false;
      console.log("确定");
    }
  },
  mounted() {
    let timer = setTimeout(() => {
      this.isShow = true;
      clearTimeout(timer);
    }, 0);
  }
};
</script>
    
<style scoped lang='less'>
.mack {
  .tipbox {
    text-align: center;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-image: url("@/assets/img/popupBg.png");
    background-size: 100% 100%;
    .Cancel_icon {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 16px;
    }
    .title {
      padding: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid #eee;
    }
    .mesg {
      color: rgba(0, 0, 0, 0.698);
      font-size: 15px;
      font-weight: 400;
      padding: 25px 16px;
    }
    .mesg1 {
      text-align: left;
      max-height: 60vh;
      overflow: scroll;
    }
    .footer {
      border-top: 1px solid #e0e0e0;
      display: inline-table;
      display: flex;
      justify-content: space-around;
      button {
        font-size: 16px;
        font-weight: 400;
        flex: 1;
        border: none;
        padding: 10px 0;
        background: none;
      }
      .cancel {
        border-right: 1px solid #e0e0e0;
      }
    }
  }
}
.van-dialog{
  background-color: transparent !important;
}
</style>