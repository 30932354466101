<template>
  <div>
    <div class="navbar">
    <!-- 返回按钮 -->
    <div @click="back" class="left">
      <van-icon name="arrow-left" color="#fff" />
      <span>{{leftText}}</span>
    </div>
    <!-- title -->
    <div class="center">
      <span :class="{chooseShow}" v-if="title">{{title}}</span>
    </div>
    <!-- 右侧自定义插槽 -->
    <div class="rightSolt">
      <slot name="right"></slot>
    </div>
  </div>
  <div class="placeholder"></div>
  </div>
</template>
    
    <script>
export default {
  props: {
    leftText: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    chooseShow: {
      type: Boolean,
      default: false
    },
    backEvent:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    back() {
      if(this.backEvent){
        return this.$emit('back')
      }

      if (window.history.length <= 1) {
        this.$router.push({ path: "/goodsDetails" });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 9px 10px;
  background-color: #fc552f;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  .center {
    flex: 1;
    height: 21px;
    text-align: center;
    font-weight: 700;
    transform: translateY(-2px);
  }
}
.placeholder{
  height:39px;
}
</style>