import Vue from 'vue';
import ele from './indexView.vue';
let confirmConstructor = Vue.extend(ele);
let login = function () {
  return new Promise((res, rej) => { //promise封装，ok执行res，no执行rej
    let confirmDom = new confirmConstructor({ el: document.createElement('div') })
    document.body.appendChild(confirmDom.$el); //new一个对象，然后插入body里面

    confirmDom.callback = function(){
      res()
    }

    confirmDom.confirm = function () {
      confirmDom.isShow = false
      let timer = setTimeout(() => {
        confirmDom.mackShow = false
        clearTimeout(timer)
      }, 150)
      res()
    }
    confirmDom.cancel = function () {
      confirmDom.isShow = false
      let timer = setTimeout(() => {
        confirmDom.mackShow = false
        clearTimeout(timer)
      }, 150)
      rej()
    }
  })
}
export default login;