import request from './request'
import { Toast } from 'vant'
// Toast.allowMultiple()
const http = (config, loadingText) => {
    if (loadingText) {
        Toast.loading({
            message: loadingText,
            forbidClick: true,
            duration: 20000
        })
    }
    return new Promise((resolve, reject) => {
        request(config).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err)
        }).finally(() => {
            if (loadingText) {
                Toast.clear()
            }
        })
    })
}

const axios = {
    /**
     * methods: 请求
     * @param url 请求地址 
     * @param params 请求参数
     */
    get(url, params, loadingText = '') {
        const config = {
            url,
            method: 'GET',
        }
        if (params) config.params = params
        return http(config, loadingText)
    },
    post(url, params, loadingText = '') {
        const config = {
            url,
            method: 'POST',
        }
        if (params) config.data = params
        return http(config, loadingText)
    },
    put(url, params, loadingText = '') {
        const config = {
            url,
            method: 'PUT',
        }
        if (params) config.data = params
        return http(config, loadingText)
    },
    delete(url, params, loadingText = '') {
        const config = {
            url,
            method: 'DELETE',
        }
        if (params) config.data = params
        return http(config, loadingText)
    }
}

export default axios