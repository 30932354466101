<template>
  <div>
    <keep-alive :include="['goodsDetails']">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'layout'

}
</script>

<style lang="less" scoped>
</style>