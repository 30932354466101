import Vue from 'vue';
import confirm from './indexView.vue';
let confirmConstructor = Vue.extend(confirm);
let theConfirm = function (text) {
  return new Promise((res, rej) => { //promise封装，ok执行res，no执行rej
    let confirmDom = new confirmConstructor({ el: document.createElement('div') })
    document.body.appendChild(confirmDom.$el); //new一个对象，然后插入body里面
    confirmDom.text = text;  //对象传入

    confirmDom.confirm = function (fn) {
      confirmDom.isShow = false
      let timer = setTimeout(() => {
        if(typeof fn == 'function') {
          fn()
        }
        confirmDom.mackShow = false
        clearTimeout(timer)
      }, 150)
      res()
    }

    window.ModelPopupCustomEvent = (fn) => confirmDom.confirm(fn)

    confirmDom.cancel = function () {
      confirmDom.isShow = false
      let timer = setTimeout(() => {
        confirmDom.mackShow = false
        clearTimeout(timer)
      }, 150)
      rej()
    }
  })
}
export default theConfirm;