<template>
  <div class="mack" v-if="mackShow">
    <van-dialog v-model="isShow" :showConfirmButton="false">
      <div class="register">
        <div class="cross_icon" @click="isShow = false">
          <van-icon name="cross" />
        </div>
        <div class="hander">
          <div class="img1">
            <div class="img1_imgbox">
              <img src="@/assets/img/tc/chinaWelfareLotteryLOGO.png" />
              <img src="@/assets/img/tc/guagualeLOGO.png" alt />
            </div>
            <div class="img1_tip">快乐购彩 理性投注</div>
          </div>
          <!-- <img class="img2" src="@/assets/img/tc/logoBG.png" /> -->
        </div>
        <div class="main">
          <div class="centent">
            <div class="centent_from">
              <van-form :show-error="false" @submit="debounceSubmit" @failed="onFailed">
                <!-- <van-field
            v-model="name"
            name="name"
            placeholder="请输入您的真实姓名"
            :rules="[{required:true ,message:'姓名不能为空'}]"
          />
          <van-field
            v-model="idNum"
            name="idNum"
            placeholder="请输入您的身份证号码"
            :rules="[{required:true, message: '身份证不能为空' },{pattern:idNumReg, message: '身份证格式有误'}]"
            error-message="请填写真实身份信息，作为大奖领取凭证"
                />-->
                <van-field
                  v-model.trim="phone"
                  name="phone"
                  type="number"
                  placeholder="请输入您的手机号"
                  :rules="[{required:true, message: '手机号不能为空' },{pattern:phoneReg, message: '手机号格式有误'}]"
                />
                <van-field
                  v-model.trim="code"
                  name="code"
                  placeholder="请输入验证码"
                  :rules="[{required:true, message: '请输入验证码' }]"
                  maxlength="6"
                  @input="inputCode"
                >
                  <template #button>
                    <van-button
                      round
                      size="small"
                      native-type="button"
                      type="danger"
                      @click="debounceGetCode"
                    >{{codeBtnText}}</van-button>
                  </template>
                </van-field>
                <div class="subBtn">
                  <van-button class="btn-Style" round block type="danger" native-type="submit">登录</van-button>
                </div>
                <!--  -->
                <div class="centent_checkbox">
                  <!-- <van-checkbox-group v-model="checked">
                    <van-checkbox name="1" checked-color="#ee0a24" />
                  </van-checkbox-group>-->
                  <!-- <van-radio-group v-model="checked">
                <van-radio name="1"></van-radio>
                  </van-radio-group>-->
                  <div class="text">
                    <p>未注册用户手机验证后自动注册</p>
                    <p>
                      注册代表同意
                      <span @click="goClick('TOS')">《服务条款》</span>
                      和
                      <span @click="goClick('privacyPolicy')">《隐私策略》</span>
                    </p>
                  </div>
                </div>
                <!--  -->
              </van-form>
            </div>
          </div>
          <!-- <hintCom :code="1" /> -->
        </div>
        <div class="call">
          <callCom />
        </div>
        <!-- <drag-resize/> -->
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getloginCode1 } from "@/api/user";
import { _debounce, toast } from "@/utils/index";
import { userStore } from "@/pinia";
import { mapWritableState } from "pinia";
import { mapActions } from "pinia";
import { Dialog } from "vant";
import router from "../../router";

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },

  data() {
    return {
      checked: ["1"],
      agreementShow: false,
      mackShow: true,
      isShow: false,
      // name: "",   //姓名
      // idNum: "",   //身份证号码
      phone: "", //手机号
      code: "", //验证码
      //   nameReg: /^[\u4E00-\u9FA5]{2,4}$/,
      idNumReg: /^(\d{18,18}|\d{15,15}|\d{17,17}x)/,
      phoneReg: /^1(3|4|5|6|7|8|9)\d{9}$/,
      codeBtnText: "获取验证码",
      isOpen: true,
      debounceGetCode: null,
      debounceSubmit: null,
      callback: () => {}
    };
  },
  computed: {
    ...mapWritableState(userStore, ["locationInfo"])
  },
  methods: {
    inputCode(e) {
      if (e.length > 6) {
        this.code = e.slice(0, 6);
      }
    },
    goClick(name) {
      router.push({ name });
      this.isShow = false;
    },
    agree() {
      this.agreementShow = false;
      this.checked = ["1"];
    },
    onFailed() {
      this.$Toast("请检查填写信息是否有误");
    },
    //校验通过提交注册
    async onSubmit() {
      this.login(this.phone, this.code).then(() => {
        this.isShow = false;
        toast("登录成功");
        this.callback();
      });
    },
    //获取验证码
    async getCode() {
      if (!this.phoneReg.test(this.phone)) return this.$Toast("手机号格式错误");
      if (this.isOpen) {
        let res = await getloginCode1(this.phone);
        console.log(res);
        if (res.clientMessage == "执行成功") {
          this.$Toast("验证码已发送");
          this.isOpen = false;
          this.codeBtnText = 90;
          const codeTime = setInterval(() => {
            this.codeBtnText--;
            if (this.codeBtnText < 0) {
              this.codeBtnText = "获取验证码";
              this.isOpen = true;
              clearInterval(codeTime);
            }
          }, 1000);
        } else {
          this.$Toast("获取失败请重试");
        }
      } else {
        this.$Toast(`请${this.codeBtnText}秒后再试`);
      }
    },
    ...mapActions(userStore, ["login", "getLocation"])
  },
  mounted() {
    let timer = setTimeout(() => {
      this.isShow = true;
      clearTimeout(timer);
    }, 0);
  },
  created() {
    console.log("resister");
    // this.getLocation()
    this.debounceGetCode = _debounce(this.getCode, 300);
    this.debounceSubmit = _debounce(this.onSubmit, 300);
  }
};
</script>

<style lang="less" scoped>
.register {
  input::input-placeholder {
    color: red;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(44, 42, 42, 0.57);
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(63, 58, 58, 0.57);
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19  */
    color: rgba(88, 83, 83, 0.57);
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10  */
    color: rgba(83, 79, 79, 0.57);
  }
  ::v-deep .van-field__body {
    border-radius: 22.5px;
    height: 45px;
    line-height: 45px;
    border: 1px solid rgb(227, 220, 220) !important;
    padding: 12px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 30px;
  background-size: cover;
  position: relative;
  .cross_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 6px;
    z-index: 10;
  }
  .hander {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7px;
    .img1 {
      .img1_imgbox {
        height: 35px;
        margin-top: 20px;
        img {
          height: 100%;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
      .img1_tip {
        color: #fff;
        margin-top: 6px;
        // text-align: center;
        // transform: translateX(52px);
      }
    }
    .img2 {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 150px;
      height: 150px;
    }
  }
  .main {
    flex: 1;
    box-shadow: 0px 0px 20px 0px #ccc;
    border-radius: 12px;
    .centent {
      border-radius: 10px;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.07),
        inset 0px 1px 20px 0px #ffffff;
      background-color: rgba(255, 255, 255, 0.116);
      padding: 22px 12px;
      .centent_from {
        ::v-deep .van-cell {
          background: none;
          overflow: visible;
          margin-bottom: 12px !important;
          padding: 0;
          input {
            // color: #fff !important;
          }
          .van-field__button {
            // height: 25px;
            transform: translateY(-5px);
            .van-button {
              min-width: 90px;
            }
          }
          &::after {
            border: none;
          }
        }
        .centent_checkbox {
          font-size: 12px;
          margin-top: 22px;
          .text {
            text-align: center;
            span {
              color: red;
            }
          }
        }
        .subBtn {
          padding: 12px 0 2px;
        }
      }
    }
  }
  .call {
    text-align: center;
    margin-top: 25px;
  }
}
</style>
  