const test = "https://hm.baidu.com/hm.js?b04e351165ff0b5828701796ca471ae8"
const prod = "https://hm.baidu.com/hm.js?80904aec834b9c8915fefc8c460c176a"

let src = process.env.NODE_ENV == 'production' ? prod : test

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = src;
  console.log(src);
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

