// 导入axios
import axios from 'axios'
import { getlocalStorage, toast } from '../index'
import { userStore } from '@/pinia';
import Vue from "vue"


//
const notDeconstructionUrl = ['/lottery/system-setting']
//1. 创建新的axios实例，
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_BASE_API,
    timeout: 20000
})

service.interceptors.request.use(config => {   //请求拦截

    let { data } = config
    if (data) {
        Object.keys(data).forEach(item => {
            if (data[item] === 'undefined' || data[item] === 'null') {
                delete data[item]
            }
        })
    }

    const token = getlocalStorage('token')
    const appId = getlocalStorage('appId')
    if (token) {
        config.headers.Authorization = 'Bearer ' + token
    }
    if(appId){
        config.headers['X-App-Id'] = appId
    }
    return config
}, error => {
    Promise.reject(error)
})

service.interceptors.response.use(async (response) => {   //响应拦截器
    console.log(response, '响应');
    if (notDeconstructionUrl.indexOf(response.config.url) != -1) {
        return response
    }
    const { data } = response
    if (data.code == '5003') {    //token超时
        const store = userStore()
        console.log(store);
        if (!store.extUserId) {
            const vm = new Vue()
            vm.$login()
            toast('登录失效请重新登录')
            return data
        } else {
            console.log('token过期');
            let { loginToKen } = await store.login()
            let config = response.config
            config.headers.Authorization = 'Bearer ' + loginToKen
            return service(config)
        }
    } else {
        return data
    }
}, error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error.code == 'ERR_NETWORK') {
        toast('网络错误')
    }
    if (error && error.response) {
        const isShowErr = error.response.data.dataMap?.doNotPlayWindow
        if (!isShowErr) {
            if(error.response.data.clientMessage){
                toast(error.response.data.clientMessage)
            }
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            toast('服务器响应超时，请刷新重试')
            return
        }
    }
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.reject(error.response.data)
})
export default service