<template>
  <div id="app">
    <keep-alive :include="['layout','orderList']">
      <router-view />
    </keep-alive>
    <backToTop />
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userStore } from "@/pinia";
import { getParams, _getVersion, setSessionStorage } from "@/utils";
import backToTop from "@/components/backToTop";
export default {
  components: { backToTop },
  data() {
    return {};
  },
  methods: {
    //初始化
    async init() {
      //渠道来源
      const source = getParams("source");
      if (source) setSessionStorage("source", source);
      //清缓存
      const reset = getParams("reset");
      if (reset) return localStorage.clear();
      //appId
      const appId = getParams("appId");
      if (appId) this.setAppId({ appId });
      //直接进入 不去获取版本号跳转详情页
      const enter = getParams("enter");
      if (enter) return;
      //extUserId
      const extUserId = getParams("extUserId");
      //邀请Id
      const shareUserId = getParams("shareUserId");
      if (shareUserId) setSessionStorage("shareUserId", shareUserId);
      //
      const { protocol, host } = window.location;
      if (appId && extUserId) {
        this.setAppId({ appId, extUserId });
        this.login().then(() => {
          _getVersion(protocol + "//" + host + "/" + "goodsDetails");
        });
      } else if (appId && !extUserId) {
        this.setAppId({ appId });
        _getVersion(protocol + "//" + host + "/" + "goodsDetails");
      }
    },
    ...mapActions(userStore, ["login", "setAppId", "getFreight"])
  },
  created() {
    this.init();
    this.getFreight();
  }
};
</script>

<style lang="less">
#app {
  font-size: 16px;
}
* {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
}
.selectSku {
  .popup_btn {
    .popup_btn_btnText {
      span {
        line-height: 28px;
      }
      .text1 {
        font-size: 15px;
        margin-right: 12px;
      }
      .text4 {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
</style>
