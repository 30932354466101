import axios from '@/utils/server/http'
//登录
// export const loginApi = (data) => axios.post('/lottery/user/login',data)

export const getloginCode1 = (num) => axios.post('/lottery/user/quick-login/verification-code/' + num ,'', '...请稍后')

//快速登录登录
export const loginApi = (data) => axios.post('/lottery/user/quick-login',data, '...登录中')
//注册手机验证码
export const getLoginCode = (num) =>axios.post('/lottery/user/verification-code/' + num,'','...请求中')
//提交注册
export const register = (data) => axios.post('/lottery/user/sign-up',data,'...正在注册')
//新增收货地址
export const add_address = (data) => axios.post('/lottery/mall-address',data,'...请稍后')
//获取用户收货地址
export const getUserAddress = () => axios.get('/lottery/mall-address','','...加载中')
//删除收货地址
export const delAddress = (id) => axios.delete('/lottery/mall-address/' + id , '' , '...请稍后')
//编辑收货地址
export const editAddress = (data) => axios.put('/lottery/mall-address',data,'...请稍后')
//设置默认收货地址
export const setDefault = (id) =>axios.put('/lottery/mall-address/default/' + id , '' , '...请稍后')
//新增订单/提交订单
export const submitOrderApi = (data) => axios.post('/lottery/mall-order',data,'...请稍后')
//查询所有订单
export const getMallOrderApi = (data) => axios.get('/lottery/mall-order/orders',data,'...查询中')
//待支付订单重新获取支付
export const getOrderPayUrlApi = (data) => axios.post('/lottery/mall-order/getOrderPayUrl',data,'...请稍后')
//确认收获
export const confirmOrderApi = (id) => axios.put('/lottery/mall-order/confirm-receipt',{id})
//订单详情
export const getOrderDetailApi = (id) => axios.get('/lottery/mall-order/' + id , '', '...查询中')
//添加到购物车
export const addCartApi = (data) => axios.post('/lottery/mall-car',data,'...请求中')
//修改购物车数量
export const editCartApi = (data) => axios.put('/lottery/mall-car',data,'...请稍后')
//查修购物车
export const getCartInfoApi = () => axios.get('/lottery/mall-car','','...努力加载中')
//查询购物车
export const getCartInfoCheckedsApi = (ids) => axios.get('/lottery/mall-car/ids?ids=' + ids,'','...加载中')
//移除购物车
export const delCartApi = (ids) => axios.delete('/lottery/mall-car/ids?ids=' + ids , '' , '...请稍后')
//查询运费
export const getFreightApi = () => axios.get('/lottery/mall-order/freight-info')
//购物车结算
export const payCartApi = (data) => axios.post('/lottery/mall-order/car',data,'...请稍后')
//获取积分明细
export const getScoreListApi = (data) => axios.get('/lottery/mall-score-history/page',data,'...请稍后')
//查询用户积分
export const getScoreInfoApi = () => axios.get('/lottery/user/mall-score')
//查询用户中奖列表  转盘
export const getActivityPrizeListApi = (data) => axios.post('/lottery/activity/user-prize-list', data, '...查询中')
//中奖奖品详情
export const getActivityDetailApi = (id) => axios.get('/lottery/activity/user-prize-info/' + id,'','...查询中')
//查询用户消费卷
export const getUserCouponsApi = (status) => axios.get('/lottery/mall-coupon-user/user/coupons/' + status, '' , '...查询中')
//根据金额查询可用消费卷
export const getOrderCouponsApi = (amount) => axios.get('/lottery/mall-coupon-user/user/pay/coupons', {amount})
//抽奖弹幕
export const winListApi = (num) => axios.get('/lottery/activity/points-draw/danmu/' + num)