import Vue from 'vue'
import App from './App.vue'
import router from './router'
//rem
import 'amfe-flexible'
// 
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
// 
import loginModel from '@/components/loginModel'
Vue.prototype.$login = loginModel
//
import theConfirm from '@/components/model'
Vue.prototype.$model = theConfirm
//
import '@/assets/css/init.css'
//
import '@/vant'
import 'vant/lib/index.css'; //vant
//
import '@/components/index.js'
//
import '@/utils/tongji'
// 
import filters from './utils/filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
//
import mixin from '@/utils/mixins'
Vue.mixin(mixin)
//
Vue.config.productionTip = false
//
if (process.env.NODE_ENV == 'production') {
  window.console.log = () => {}
}
//
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default Vue
