import axios from "axios"
import { getSetting } from '@/api/page'
import { getGoodsInfoApi } from '@/api/goods'
import { payCartApi, submitOrderApi, getOrderPayUrlApi } from '@/api/user'
import { Toast } from 'vant'
import mokeGoods from "@/moke/goods.json";
import router from "@/router"
import { userStore } from "@/pinia"

//
export function sleep(time = 500) {
    return new Promise((resolve) => {
        let timer = setTimeout(() => {
            clearTimeout(timer)
            resolve()
        }, time);
    })
}

export const goodsId = 2   //商品id
export var company = "上海炫怡信息科技有限公司";   //公司
export var abbreviation = "上海炫怡";             //公司简称
export var organization = '体育彩票发行中心'        //机构
export var organizationAddress = '重庆市渝北区红锦大道52号福彩大厦'  //机构地址
export var organizationAbbreviation = '体彩中心'    //机构简称
export var lotteryName = "重庆体彩"
export var organizationTypeName = '体彩'
export var redeemApplet = '全名拼好运小程序'        //兑奖小程序名称
export var projectName = "送票到家"         //项目名称
document.title = lotteryName
export const service = {    //客服联系方式
    phone: '023-67521611',
    weChat: 'wxid_ddrnj4rsbgrf22',
    weChatQRcode: require('@/assets/img/fc/weChatQRcode.jpg')
}

//订单超时时间  30分钟
export const orderTimeOut = 30 * 60 * 1000

//获取商品信息
export function getGoodDetail() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        let res = await getGoodsInfoApi(goodsId);
        console.log(res);
        if (res?.code == 0) {
            res.data.productGames = res.data.productGames.filter(item => {
                if (item.productTypes && item.productTypes.length) {
                    return item
                }
            })
            resolve(res.data)
        } else if (process.env.NODE_ENV != "production") {
            resolve(mokeGoods)
        }
    })
}

function paidRedirectUrl() {
    const source = getOrderSource()
    let url = window.location.origin + '/pay-iframe.html'
    if (source == 1004) {
        url = window.location.origin + '/h5PayCallback'
    }
    return url
}
//提交订单
export async function submitOrder(data, type = 'cart') {
    data.paidRedirectUrl = paidRedirectUrl()
    return new Promise((resolve) => {
        let api = payCartApi
        if (type != 'cart') {
            api = submitOrderApi
        }
        api(data)
            .then(async res => {
                console.log(res, 'res');
                if (res.code == 0) {
                    if (res.data.orderPayUrl) {
                        await sleep(300);
                        Toast.loading({
                            message: "...请稍后",
                            overlay: true,
                            forbidClick: true,
                            duration: 0
                        });
                        window.location.replace(res.data.orderPayUrl);
                        // window.open(res.data.orderPayUrl,"_blank")
                    } else {
                        const store = userStore()
                        router.replace({ name: store.paths.orderList })
                        await sleep()
                        toast('下单成功')
                    }
                }
            })
            .catch(async err => {
                console.log(err, 'err');
                const Model = require('@/components/model/index').default
                console.log(Model);
                Model({
                    content: err.clientMessage,
                    noCancelShow: true,
                    title: "订单错误"
                });
            })
            .finally(() => resolve());
    })
}

//重新支付
export async function againPay(orderId) {
    let res = await getOrderPayUrlApi({
        orderId,
        orderSource: getOrderSource(),
        paidRedirectUrl: paidRedirectUrl()
    })
    console.log(res);
    if (res.code == 0) {
        window.location.href = res.data;
    }
}

//商品规格的状态
export function goodsTypeStatus(status) {
    console.log(status);
    let text = ''
    let disabled = false
    status = Number(status)
    switch (status) {
        case 2:
            text = '已下架'
            disabled = true
            break;
        case 3:
            text = '已下架'
            disabled = true
            break;
        case 4:
            text = '已售罄'
            disabled = true
            break;

    }
    return { text, disabled }
}

//订单状态
export function statusFilter(status) {
    let btnText = "";   //订单列表按钮文字
    let statusText = "";  //状态的文字
    let textColor = "";   //对应状态文字颜色
    let btnColor = "linear-gradient(to right, #ff6034, #ee0a24)"; //订单列表对应状态按钮颜色
    let btnDisabled = false;  //订单列表是否禁用按钮
    let showLogistics = false  //详情页是否显示物流
    if (status == 0) {
        btnText = "立即支付";
        statusText = "待支付";
        textColor = "#327050";
        btnColor = "linear-gradient(to right, #94e0b7,#327050 )";
    } else if (status == 1) {
        btnText = "重新下单";
        statusText = "订单超时";
        textColor = "#f56c6c";
    } else if (status == 2 || status == 100 || status == 101 || status == 102) {
        btnText = "待发货";
        statusText = "待发货";
        textColor = "#e6a23c";
        btnDisabled = true;
        btnColor = "#aaa";
    } else if (status == 3) {
        btnText = "确认收货";
        statusText = "待收货";
        textColor = "#409eff";
        showLogistics = true
    } else if (status == 4) {
        btnText = "再来一单";
        statusText = "订单完成";
        textColor = "#67c23a";
        btnColor = "linear-gradient(to right, #96d356, #67c23a)";
        showLogistics = true
    } else if (status == 5) {
        btnText = "重新下单";
        statusText = "已取消";
        textColor = "#f56c6c";
    } else if (status == 6 || status == 7) {
        btnText = "重新下单";
        statusText = "已拒收";
        textColor = "#f56c6c";
    } else if (status == 103) {
        btnText = "配送中";
        statusText = "商家配送";
        textColor = "#fc552f";
        btnDisabled = true;
        btnColor = "#aaa";
    }
    // else if(status == 7){
    //   btnText = "重新下单";
    //   statusText = "已退回";
    //   textColor = "#f56c6c";
    // }
    return {
        btnText,
        statusText,
        textColor,
        btnDisabled,
        btnColor,
        showLogistics
    };
}


export function toast(msg) {
    let timer = setTimeout(() => {
        Toast(msg)
        clearTimeout(timer)
    }, 250)
}

//深拷贝
export function deepCopy(data) {
    return jsonP(jsonS(data))
}

export function jsonS(obj) {
    return JSON.stringify(obj)
}

export function jsonP(str) {
    return JSON.parse(str)
}

//获取本地存储localStorage
export function getlocalStorage(key) {
    return localStorage.getItem(key)
}
//本地存储localStorage
export function setSessionStorage(key, value) {
    return sessionStorage.setItem(key, value)
}
//获取本地存储sessionStorage
export function getSessionStorage(key) {
    return sessionStorage.getItem(key)
}
//本地存储setlocalStorage
export function setlocalStorage(key, value) {
    return localStorage.setItem(key, value)
}
//截取url参数
export function getParams(key) {
    var result = {}
    var str = window.location.search;
    if (str.startsWith('?')) {
        var strParams = str.split('?')[1];
        var arrParams = strParams.split('&');
        arrParams.forEach((item) => {
            var temKey = item.split('=')[0];
            var temVal = item.split('=')[1];
            result[temKey] = temVal
        })
    }
    return result[key]
}

// 防抖
export function _debounce(fn, delay) {
    delay = delay || 200;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay);
    };
}
// 节流
export function _throttle(fn, interval) {
    var last;
    var timer;
    interval = interval || 200;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, interval);
        } else {
            last = now;
            fn.apply(th, args);
        }
    }
}
/**
   * 检查设备
*/
const u = navigator.userAgent.toLowerCase()

// 安卓环境
export function inAndroid() {
    return u.indexOf('android') > -1 || u.indexOf('linux') > -1
}

// IOS环境
export function inIos() {
    return !!u.match(/\(i[^;]+;( u;)? cpu.+mac os x/)
}

// 支付宝环境
export function inAliPay() {
    return u.match(/AlipayClient/i) == "alipayclient"
}
// 微信环境
export function inWeChat() {
    return u.match(/MicroMessenger/i) == "micromessenger"
}
//微信小程序环境
export function inMiniprogram() {
    return window?.__wxjs_environment === 'miniprogram'
}
//app环境
export function inApp() {
    console.log(u);
    return /(^|;\s)app\//.test(u)
}

//获取当前下单的环境
export function getOrderSource() {
    let source = getSessionStorage('source')
    if (!source) {
        source = 1001
        const isInWeChat = inWeChat()
        if (isInWeChat) {
            if (inMiniprogram()) {
                source = 1003   //微信小程序
            } else {
                source = 1002   //微信H5
            }
        } else {
            const isInApp = inApp()
            if (isInApp) {
                source = 1004  //App
            }
        }
    }
    console.log(source, '下单的环境');
    return source
}
getOrderSource()






//url添加版本跳转
export const _getVersion = async (url) => {
    getSetting().then((res) => {
        if (res.data.code == 0) {
            const { version, activityList } = res.data.data
            if (activityList.length) {
                setSessionStorage('activityList', JSON.stringify(activityList))
            }
            window.location.replace(url.indexOf("?") > -1 ? `${url}&timestamp=${version}` : `${url}?=timestamp${version}`)
        }
    }).catch(() => {
        window.location.replace(url)
    })
}
//定位
export function location() {
    return new Promise((resolve) => {
        let lng   //经度
        let lat   //纬度
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((event) => {
                let n = transformFromWGSToGCJ(event.coords.latitude, event.coords.longitude)
                lng = n.longitude
                lat = n.latitude
                console.log('经度' + lng + '|' + '纬度' + lat)
                // geocoder({ lat, lng })
                resolve({ lng, lat })
            }, (error) => {
                console.log(error);
                //腾讯定位
                var geolocation = new window.qq.maps.Geolocation('R7KBZ-FGSLO-ONDW7-SCSZZ-FWXWH-QOFFN', '乐乐卡');
                geolocation.getLocation(
                    async (res) => {
                        console.log(res, '定位');
                        lng = res.lng
                        lat = res.lat
                        // geocoder({ lat, lng })
                        resolve({ lat, lng })
                    },
                    (err) => {
                        console.log(err);
                    },
                    {
                        failTipFlag: true
                    }
                );
            })
        } else {
            // 不支持
            // Notify("不支持地理位置接口");
        }
    })
}
//经纬度转gcj02
function transformFromWGSToGCJ(latitude, longitude) {
    var ee = 0.00669342162296594323;
    var a = 6378245.0;
    var pi = 3.14159265358979324;
    var adjustLat = transformLatWithXY(longitude - 105.0, latitude - 35.0);
    var adjustLon = transformLonWithXY(longitude - 105.0, latitude - 35.0);
    var radLat = latitude / 180.0 * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    adjustLat = (adjustLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
    adjustLon = (adjustLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
    latitude = latitude + adjustLat;
    longitude = longitude + adjustLon;
    return {
        latitude: latitude,
        longitude: longitude
    };
}

//经纬度转gcj02
function transformLatWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lat = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    lat += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lat += (20.0 * Math.sin(y * pi) + 40.0 * Math.sin(y / 3.0 * pi)) * 2.0 / 3.0;
    lat += (160.0 * Math.sin(y / 12.0 * pi) + 320 * Math.sin(y * pi / 30.0)) * 2.0 / 3.0;
    return lat;
}

//经纬度转gcj02
function transformLonWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lon = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    lon += (20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0 / 3.0;
    lon += (20.0 * Math.sin(x * pi) + 40.0 * Math.sin(x / 3.0 * pi)) * 2.0 / 3.0;
    lon += (150.0 * Math.sin(x / 12.0 * pi) + 300.0 * Math.sin(x / 30.0 * pi)) * 2.0 / 3.0;
    return lon;
}
//经纬度转地址
export const geocoder = (location) => {
    const province = getSessionStorage('province')
    if (!province) {
        axios.get(`/qqMap/ws/geocoder/v1?key=R7KBZ-FGSLO-ONDW7-SCSZZ-FWXWH-QOFFN&location=${location.lat},${location.lng}`).then(res => {
            const province = res.data.result.address_component.province
            setSessionStorage('province', province)
        })
    }
}
//IP定位
export const location_Ip = (ip) => {
    return new Promise((resolve) => {
        axios({ url: '/qqMap/ws/location/v1/ip', params: { key: 'R7KBZ-FGSLO-ONDW7-SCSZZ-FWXWH-QOFFN', ip }, method: 'get' }).then((res) => {
            console.log(res);
            const province = res.data.result.ad_info.province
            setSessionStorage('province', province)
            resolve(true)
        }).catch(() => {
            resolve(true)
        })
    })
}

export function returnFloat(amount) {
    return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

//时间
export function shijianc(time) {
    let date = new Date(time)
    let Y = date.getFullYear() + '-'
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
    let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    return { Y, M, D, h, m, s }
}

//时间转时间戳
export function dateToTimestamp(dateString) {
    // 创建一个新的日期对象
    let date = new Date(dateString.replace(/-/g, '/'));
    // 获取日期对象的时间戳
    let timestamp = date.getTime();
    // 返回时间戳（毫秒为单位）
    return timestamp;
}

//复制
export function copyText(text, toastText = '') {
    var input = document.createElement('input');
    input.setAttribute('id', 'input_for_copyText');
    input.value = text;
    document.getElementsByTagName('body')[0].appendChild(input);
    document.getElementById('input_for_copyText').select();
    document.execCommand('copy');
    document.getElementById('input_for_copyText').remove();
    toast(toastText === '' ? '已复制' : toastText)
}

//随机数
export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//新用户优惠券弹窗
export function couponPopup(data){
    const Model = require('@/components/model/index').default
    const bg = require('@/assets/img/couponPopup/bg.png')
    const bgBtn = require('@/assets/img/couponPopup/bg_btn.png')
    const cancel = require('@/assets/img/cancel.svg')
    Model({
        custom:`
            <div
            class="couponPopup"
            style="background-image: url(${bg});">
                <p class="couponPopup_text1">${data.name}</p>
                <p class="couponPopup_text2">${data.amount}<span>元</span></p>
                <p class="couponPopup_text3">顶呱刮抵扣卷</p>
                <p 
                class="couponPopup_text4"
                style="background-image: url(${bgBtn});">
                立即使用</p>
            </div>
            <div class="couponPopup_cancel">
                <img id="couponPopup_cancel_icon" src="${cancel}" >
            </div>
        `
    })
    setTimeout(() => {
        document.querySelector('#couponPopup_cancel_icon').addEventListener('click',window.ModelPopupCustomEvent)
        document.querySelector('.couponPopup_text4').addEventListener('click',() => {
            window.ModelPopupCustomEvent(() => {
                const router = require('@/router').default
                router.push({name:'goodsDetails'})
            })
        })
    },500)
}


//获取公众号code
export const getCode = (state) => {
    const appid = 'wx6a9f4b8ceafe2063'
    // const { protocol, host } = window.location;
    const redirectUrl = window.location.href
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${window.encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect&forcePopup=true`
}


//
export function removeURLParameter(parameter) {
    var url = window.location.href;
    var urlObj = new URL(url);
  
    // 获取查询参数对象
    var params = urlObj.searchParams;
  
    // 删除指定参数
    params.delete(parameter);
  
    // 生成更新后的URL
    var updatedURL = urlObj.origin + urlObj.pathname + '?' + params.toString() + urlObj.hash;
  
    // 使用history.replaceState()替换当前的历史记录条目
    history.replaceState({}, '', updatedURL);
  }