<template>
  <a :href="`tel:${service_phone}`" class="callView">
    客服电话:
    <span>{{service_phone}}</span>
  </a>
</template>
  
<script>
export default {};
</script>
<style lang="less" scoped>
.callView {
  text-align: center;
  color: #aaa;
  font-size: 13px;
  span {
    color: #4da6f2;
  }
}
</style>