import { returnFloat } from '@/utils'
export default {
    formatName(name) {
        let newStr;
        if (name.length === 2) {
            newStr = name.substr(0, 1) + '*';
        } else if (name.length > 2) {
            let char = '';
            for (let i = 0, len = name.length - 2; i < len; i++) {
                char += '*';
            }
            newStr = name.substr(0, 1) + char + name.substr(-1, 1);
        } else {
            newStr = name;
        }

        return newStr;
    },
    returnFloat(num){
        return returnFloat(num)
    }
}